
export const getStoredLanguage = () => {

    if (typeof window === 'undefined' && typeof localStorage === 'undefined') {
        // check if server-side or client browser
        return 'en';
    }
    
    const storedLanguage = localStorage.getItem('userPreferredLanguage');
    // TODO: add checks for valid language?
    if (storedLanguage && storedLanguage.length === 2) {
        // assume it is a language code
        return storedLanguage
    } else {
        return undefined;
    };
};

export const getAssetUrlLanguageTrail = () => {
    const languageCode = getStoredLanguage();
    if (languageCode && languageCode !== "en") {
        return `_${languageCode}`;
    } else {
        return '';
    }
}

export const detectLanguage = async (text) => {
    const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY
    const response = await fetch(`https://translation.googleapis.com/language/translate/v2/detect?key=${apiKey}`, {
      method: 'POST',
      body: JSON.stringify({ q: text }),
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await response.json();
    return data.data.detections[0][0].language;
};
  