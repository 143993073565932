import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import BrandedPrompt from '../BrandedPrompt/BrandedPrompt';
import './InstallPrompt.css';
import { isMobileSafari } from '../../util/userAgent';

function getStored() {
    if (typeof window === 'undefined') return true; // for SSR

    const value = localStorage.getItem('appinstallPromptShown');
    if (value) {
        return value === 'true';
    } else {
        localStorage.setItem('appinstallPromptShown', false);
        return false;
    }
}

const InstallPromptComponent = ({ intl }) => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showPrompt, setShowPrompt] = useState(false);

    const isStandaloneMode = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

    const delayedPromptShow = () => {
        if (!isStandaloneMode || !getStored()) {
            setShowPrompt(true);
        };
    };

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            console.log("delayedPromptShow in", 4500);
            setTimeout(delayedPromptShow, 4500);
        };

        if (isMobileSafari()) {
            console.log("mobile safari detected");
            setTimeout(delayedPromptShow, 4500);
        } else {
            window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            console.log("added beforeinstallprompt event listener");
        }


        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            localStorage.setItem('appinstallPromptShown', true);
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                };

                // Reset the deferredPrompt to null after the user has made a choice
                setDeferredPrompt(null);
                setShowPrompt(false);
            }).catch((error) => {
                console.error('Error during the install prompt:', error);
                setDeferredPrompt(null);
                setShowPrompt(false);
            });
        }
    };

    const handleClosePromptClick = () => {
        localStorage.setItem('appinstallPromptShown', true);
        setDeferredPrompt(null);
        setShowPrompt(false);
    };

    if (!showPrompt || isStandaloneMode || getStored()) {
        return null;
    }

    return isMobileSafari() ? (
        <BrandedPrompt
            headerText={intl.formatMessage({ id: 'InstallPrompt.safariHeader' })}
            descriptionText={intl.formatMessage({ id: 'InstallPrompt.safariDescription' })}
            acceptButtonText={intl.formatMessage({ id: 'InstallPrompt.safariAcceptButton' })}
            onAcceptClick={handleClosePromptClick}
        />
    ) : <BrandedPrompt
        headerText={intl.formatMessage({ id: 'InstallPrompt.header' })}
        descriptionText={intl.formatMessage({ id: 'InstallPrompt.description' })}
        acceptButtonText={intl.formatMessage({ id: 'InstallPrompt.installButton' })}
        closeButtonText={intl.formatMessage({ id: 'InstallPrompt.closeButton' })}
        onAcceptClick={handleInstallClick}
        onCloseClick={handleClosePromptClick}
    />
        ;
};

const InstallPrompt = injectIntl(InstallPromptComponent);

export default InstallPrompt;
