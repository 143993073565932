import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { getAssetUrlLanguageTrail } from '../../util/translations';

export const loadData = (params, search) => dispatch => {
  const pageId = params.pageId;
  const pageAsset = { [pageId]: `content/pages/${pageId}${getAssetUrlLanguageTrail()}.json` };
  const hasFallbackContent = false;
  const fallbackAsset = { [pageId]: `content/pages/${pageId}.json` };
  return dispatch(fetchPageAssets(pageAsset, hasFallbackContent, fallbackAsset));
};
