import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';

import { getStoredLanguage, detectLanguage } from '../../util/translations';
import translateWeglot from '../../translations/useWeglot';

import css from './TranslateButton.module.css'

export function calculatePostLanguage(authorProfile) {
    // Try to figure out the post's language
    if (!authorProfile) { return 'en'; };
    if (authorProfile.publicData && authorProfile.publicData.userLanguage) {
        // if it is defined, okay
        const postLanguage = authorProfile.publicData.userLanguage;
        return postLanguage;
    } else {
        // if not defined in the profile
        // if the receiver's own language is english, then sent message was probably pt
        // and vica versa
        let postLanguage = getStoredLanguage() === 'en' ? 'pt' : 'en';
        postLanguage = getStoredLanguage() === 'pt' ? 'en' : 'pt';
        return postLanguage;
    };
}

const TranslateButton = props => {

    const { authorProfile, originalText, setTranslatedText } = props;

    const [isTranslated, setIsTranslated] = useState(false);
    const [translationFailed, setTranslationFailed] = useState(false);
    const [showButton, setShowButton] = useState(true);

    const postLanguage = calculatePostLanguage(authorProfile);

    const handleTranslate = async () => {
        console.log('postLanguage', postLanguage, 'getStoredLanguage', getStoredLanguage())

        let langCode = await detectLanguage(text);

        if (!langCode) {
            langCode = postLanguage;
            console.log("Language detection failed, using fallback postLanguage:", langCode);
        }

        if (langCode === getStoredLanguage()) {
            console.log("same languages, not going to translate");
            setShowButton(false);
            return;
        };

        if (!isTranslated) {
            console.log('pressed translate')
            const translated = await translateWeglot(originalText, langCode, getStoredLanguage());

            if (translated) {
                setTranslatedText(translated);
                setIsTranslated(true);
                setTranslationFailed(false);

            } else {
                console.log("translation failed");
                setTranslationFailed(true);
            }
        } else {
            console.log('pressed show original')
            setTranslatedText(originalText);
            setIsTranslated(false);
        }
    };

    if (!showButton) return;
    
    return (
        <InlineTextButton
            className={css.translateButton}
            onClick={handleTranslate}
        >
            <FormattedMessage
                id={translationFailed ? 'TranslateButton.translationFailed'
                    : isTranslated ? 'TranslateButton.showOriginal' :
                        'TranslateButton.translate'} />
        </InlineTextButton>
    )
}

export default TranslateButton;