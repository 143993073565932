import React from 'react';

const LanguageSelector = ({ currentLanguage, onChangeOuter }) => {

  let languages = [
    { code: 'en', name: '🇬🇧 English' },
    { code: 'pt', name: '🇵🇹 Português' },
    { code: 'fr', name: '🇫🇷 Français' },
    { code: 'de', name: '🇩🇪 Deutsch' },
    { code: 'es', name: '🇪🇸 Espanhol' },
    // Add more languages as needed
  ];

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  if (isMobile) {
    languages = [
      { code: 'en', name: '🇬🇧' },
      { code: 'pt', name: '🇵🇹' },
      { code: 'fr', name: '🇫🇷' },
      { code: 'de', name: '🇩🇪' },
      { code: 'es', name: '🇪🇸' }
    ];
  }

  return (
    <div style={styles.languageSelector}>
      <select value={currentLanguage} onChange={(e) => { onChangeOuter(e) }}>
        {languages.map(language => (
          <option key={language.code} value={language.code}>
            {language.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const styles = {
  languageSelector: {
    position: 'fixed',
    bottom: '2px',
    right: '2px',
    zIndex: '9999',
    minWidth: 'fit-content',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '2px',
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'rgba(0,0,0,0)'
  },
};

export default LanguageSelector;
