export const isMobileSafari = () => {
  if (!window) {
    return false;
  }

  // https://stackoverflow.com/a/29696509
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iChrome = !!ua.match(/CriOS/i);

  // If iOS Chrome needs to be separated, use `!ua.match(/CriOS/i)` as
  // an extra condition.
  return iOS && webkit && !iChrome;
};

export const isMobileDevice = () => {

  if (typeof window === 'undefined') { return false };
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // console.log(userAgent);

  // Regular expression to check if the user agent string matches a mobile device
  const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|opera mobi/i;
  
  // console.log("isMobileDevice mobileRegex", mobileRegex.test(userAgent))
  
  return mobileRegex.test(userAgent);
};