import React from 'react';
import PropTypes from 'prop-types';
import './BrandedPrompt.module.css';

import css from './BrandedPrompt.module.css'

const BrandedPrompt = ({ headerText, descriptionText, acceptButtonText, closeButtonText, onAcceptClick, onCloseClick }) => {
    return (
        <div className={css.brandedPromptOverlay}>
            <div className={css.brandedPromptContainer}>
                {headerText? <h2>{headerText}</h2> :null }
                <p>{descriptionText}</p>
                <div className={css.buttonsContainer}>
                {acceptButtonText ? <button className={css.acceptButton} onClick={onAcceptClick}>{acceptButtonText}</button> : null}
                {closeButtonText ? <button className={css.closeButton} onClick={onCloseClick}>{closeButtonText}</button> : null}
                </div>
            </div>
        </div>
    );
};

BrandedPrompt.propTypes = {
    headerText: PropTypes.string,
    descriptionText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    closeButtonText: PropTypes.string,
    onAcceptClick: PropTypes.func,
    onCloseClick: PropTypes.func,
};

export default BrandedPrompt;
