import axios from 'axios';

const translateWeglot = async (text, languageFrom, languageTo, pageTitle) => {
  const apiKey = process.env.REACT_APP_WEGLOT_API_KEY; // Replace with your actual API key
  const apiUrl = `https://api.weglot.com/translate?api_key=${apiKey}`;

  const title = pageTitle? pageTitle : "Message feed about marketplace inquiry";

  try {
    const response = await axios.post(apiUrl, {
      // api_key: apiKey,
      l_from: languageFrom,
      l_to: languageTo,
      request_url: process.env.REACT_APP_MARKETPLACE_ROOT_URL,
      words: [{ w: text, t: 1 }],
      title: title
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const translatedTextList = response.data.to_words;
    return translatedTextList;
  } catch (error) {
    console.error('Error translating text:', error);
    return null;
  }
};

export default translateWeglot;
