import en from './en.json'; // Import English translations
import pt from './pt.json'; // Import Portuguese translations
import fr from './fr.json'; // Import
import de from './de.json'; // Import
import es from './es.json'; // Import

import { getStoredLanguage, detectLanguage } from '../util/translations';

// Object to hold all translations keyed by language code
export const translations = {
  en: en,
  pt: pt,
  fr: fr,
  de: de,
  es: es
};


export function getTranslation(translationsDict, key) {
  if (translationsDict[key] && translationsDict[key][getStoredLanguage()]) {
    return translationsDict[key][getStoredLanguage()];
  }
  return key;
}

export function renderTitle(data, defaultTitle) {
  const language = getStoredLanguage();
  const titleKey = `title${language.charAt(0).toUpperCase() + language.slice(1)}`;
  const translatedTitle = Array.isArray(data[titleKey]) ? data[titleKey][0] : data[titleKey];
  const title = translatedTitle || defaultTitle;
  
  return title;
}

export const userTypeTranslations = {
  "Private person": {
    en: "Private person",
    pt: "Pessoa privada",
    de: "Privatperson",
    fr: "Personne privée",
    es: "Persona privada"
  },
  "Business": {
    en: "Business",
    pt: "Negócio",
    de: "Geschäft",
    fr: "Entreprise",
    es: "Negocio"
  }
};

export const footerMarkdownLinktextsTranslations = {
  'About us': {
    en: 'About us',
    pt: 'Sobre nós',
    de: 'Über uns',
    fr: 'À propos de nous',
    es: 'Sobre nosotros'
  },
  'Be Atlistis Kind': {
    en: 'Be Atlistis Kind',
    pt: 'Seja Atlistis Kind',
    de: 'Atlistis Kind sein',
    fr: 'Etre Atlistis Kind',
    es: 'Sé Atlistis Kind'
  },
  'Terms of Service': {
    en: 'Terms of service',
    pt: 'Termos de serviço',
    de: 'Nutzungsbedingungen',
    fr: "Conditions d'utilisation",
    es: 'Términos de servicio'
  },
  'Privacy Policy': {
    en: 'Privacy Policy',
    pt: 'Política de privacidade',
    de: 'Datenschutzrichtlinie',
    fr: 'Politique de confidentialité',
    es: 'Política de privacidad'
  },
  'Search listings': {
    en: 'Search listings',
    pt: 'Pesquisa anúncious',
    de: 'Einträge durchsuchen',
    fr: 'Rechercher des annonces',
    es: 'Buscar listados'
  },
  'Post a new listing': {
    en: 'Post a new listing',
    pt: 'Publica um novo anúncio',
    de: 'Neuen Eintrag erstellen',
    fr: 'Déposer un annonce',
    es: 'Publicar anuncio'
  },
  'Get the App': {
    en: 'Get the App',
    pt: 'Obter a App',
    de: 'Hol dir die App',
    fr: "Obtenir l'application",
    es: 'Obtener la aplicación'
  },
  'How-to guides': {
    en: 'How-to guides',
    pt: 'Instruções',
    de: 'Anleitungen',
    fr: "Guides pratiques",
    es: 'Guías prácticas'
  },
  'Contact us': {
    en: 'Contact us',
    pt: 'Contate-nos',
    de: 'Kontakt',
    fr: 'Contactez-nous',
    es: 'Contacto'
  }
}

export const footerCopyTranslations = {
  'Atlistis: Where Azores Adventures Begin': {
    en: 'Atlistis: Where Azores Adventures Begin',
    pt: 'Atlistis: Onde Começam as Aventuras nos Açores',
    de: 'Atlistis: Wo die Abenteuer auf den Azoren beginnen',
    fr: 'Atlistis: Là où commencent les aventures aux Açores',
    es: 'Atlistis: Donde comienzan las aventuras en Azores'
  },
  '© 2024 Atlistis. All rights reserved.': {
    en: '© 2024 Atlistis. All rights reserved.',
    pt: '© 2024 Atlistis. Todos os direitos reservados.',
    de: '© 2024 Atlistis. Alle Rechte vorbehalten.',
    fr: '© 2024 Atlistis. Tous droits réservés.',
    es: '© 2024 Atlistis. Todos los derechos reservados.'
  },
}

export const topbarCustomlinksTranslations = {
  'test menu item': {
    en: 'This is a test item',
    pt: 'Um item de teste',
    de: 'Ein Testitem',
    fr: 'teste item',
    es: 'teste item'
  },
  'See events': {
    en: 'See events',
    pt: 'Ver eventos'
  },
  'See islands': {
    en: 'See islands',
    pt: 'Ver ilhas',
    de: 'Inseln sehen',
    fr: 'Voir les îles',
    es: 'Ver islas'
  },
  'How to use': {
    en: 'How to use',
    pt: 'Instruções',
    de: 'Anleitungen',
    fr: "Guides pratiques",
    es: 'Guías prácticas'
  }
}

export const categoriesTranslations = {
  "Automotive parts": {
    en: "Automotive parts",
    pt: "Partes automotivas",
    de: "Autoteile",
    fr: "Pièces automobiles",
    es: "Piezas automotrices"
  },
  "Boats": {
    en: "Boats",
    pt: "Barcos",
    de: "Boote",
    fr: "Bateaux",
    es: "Barcos"
  },
  "Cars, trucks": {
    en: "Cars, trucks",
    pt: "Carros, caminhões",
    de: "Autos, LKWs",
    fr: "Voitures, camions",
    es: "Coches, camiones"
  },
  "Motorbikes, eBikes": {
    en: "Motorbikes, eBikes",
    pt: "Motocicletas, eBikes",
    de: "Motorräder, eBikes",
    fr: "Motos, vélos électriques",
    es: "Motos, bicicletas eléctricas"
  },
  "Other": {
    en: "Other",
    pt: "Outro",
    de: "Andere",
    fr: "Autre",
    es: "Otro"
  },
  "Automotive": {
    en: "Automotive",
    pt: "Automotivo",
    de: "Automobil",
    fr: "Automobile",
    es: "Automotriz"
  },
  "Babies & Kids": {
    en: "Babies & Kids",
    pt: "Bebês & crianças",
    de: "Babys & Kinder",
    fr: "Bébés & enfants",
    es: "Bebés y niños"
  },
  "Women": {
    en: "Women",
    pt: "Senhoras",
    de: "Frauen",
    fr: "Femmes",
    es: "Mujeres"
  },
  "Men": {
    en: "Men",
    pt: "Homens",
    de: "Männer",
    fr: "Hommes",
    es: "Hombres"
  },
  "Clothing": {
    en: "Clothing",
    pt: "Roupas",
    de: "Kleidung",
    fr: "Vêtements",
    es: "Ropa"
  },
  "Art supplies": {
    en: "Art supplies",
    pt: "Materiais de arte",
    de: "Kunstbedarf",
    fr: "Fournitures d'art",
    es: "Materiales de arte"
  },
  "Fine Arts": {
    en: "Fine Arts",
    pt: "Belas-Artes",
    de: "Schöne Künste",
    fr: "Beaux-Arts",
    es: "Bellas Artes"
  },
  "Souvenirs": {
    en: "Souvenirs",
    pt: "Lembranças",
    de: "Souvenirs",
    fr: "Souvenirs",
    es: "Recuerdos"
  },
  "Arts & Souvenirs": {
    en: "Arts & Souvenirs",
    pt: "Artes & lembranças",
    de: "Kunst & Souvenirs",
    fr: "Arts & souvenirs",
    es: "Artes y recuerdos"
  },
  "Cameras": {
    en: "Cameras",
    pt: "Câmeras",
    de: "Kameras",
    fr: "Appareils photo",
    es: "Cámaras"
  },
  "Computers": {
    en: "Computers",
    pt: "Computadores",
    de: "Computer",
    fr: "Ordinateurs",
    es: "Ordenadores"
  },
  "Phones & tablets": {
    en: "Phones & tablets",
    pt: "Telefones e tablets",
    de: "Telefone & Tablets",
    fr: "Téléphones et tablettes",
    es: "Teléfonos y tabletas"
  },
  "Gadgets": {
    en: "Gadgets",
    pt: "Dispositivos",
    de: "Gadgets",
    fr: "Gadgets",
    es: "Aparatos"
  },
  "Eletronics": {
    en: "Eletronics",
    pt: "Eletrônica",
    de: "Elektronik",
    fr: "Électronique",
    es: "Electrónica"
  },
  "Agriculture": {
    en: "Agriculture",
    pt: "Agricultura",
    de: "Landwirtschaft",
    fr: "Agriculture",
    es: "Agricultura"
  },
  "Animals": {
    en: "Animals",
    pt: "Animais",
    de: "Tiere",
    fr: "Animaux",
    es: "Animales"
  },
  "Furniture": {
    en: "Furniture",
    pt: "Mobília",
    de: "Möbel",
    fr: "Mobilier",
    es: "Muebles"
  },
  "Gardening": {
    en: "Gardening",
    pt: "Jardinagem",
    de: "Gartenarbeit",
    fr: "Jardinage",
    es: "Jardinería"
  },
  "Home equipment": {
    en: "Home equipment",
    pt: "Equipamento doméstico",
    de: "Haushaltsgeräte",
    fr: "Équipement domestique",
    es: "Equipo del hogar"
  },
  "Home & Garden": {
    en: "Home & Garden",
    pt: "Casa & jardim",
    de: "Haus & Garten",
    fr: "Maison & jardin",
    es: "Casa y jardín"
  },
  "Bevarages": {
    en: "Bevarages",
    pt: "Bebidas",
    de: "Getränke",
    fr: "Boissons",
    es: "Bebidas"
  },
  "Groceries": {
    en: "Groceries",
    pt: "Mantimentos",
    de: "Lebensmittel",
    fr: "Épicerie",
    es: "Comestibles"
  },
  "Local produce": {
    en: "Local produce",
    pt: "Produção local",
    de: "Regionale Produkte",
    fr: "Produits locaux",
    es: "Productos locales"
  },
  "Local Produce, Groceries & Bevarages": {
    en: "Local Produce, Groceries & Bevarages",
    pt: "Produtos locais, mantimentos & bebidas",
    de: "Regionale Produkte, Lebensmittel & Getränke",
    fr: "Produits locaux, épicerie & boissons",
    es: "Productos locales, comestibles y bebidas"
  },
  "Tools, Power Tools": {
    en: "Tools, Power Tools",
    pt: "Ferramentas, Ferramentas Elétricas",
    de: "Werkzeuge, Elektrowerkzeuge",
    fr: "Outils, outils électriques",
    es: "Herramientas, herramientas eléctricas"
  },
  "Building machinery": {
    en: "Building machinery",
    pt: "Máquinas de construção",
    de: "Baumaschinen",
    fr: "Machines de construction",
    es: "Maquinaria de construcción"
  },
  "Work equipment": {
    en: "Work equipment",
    pt: "Equipamento de trabalho",
    de: "Arbeitsausrüstung",
    fr: "Équipement de travail",
    es: "Equipo de trabajo"
  },
  "Professional tools": {
    en: "Professional tools",
    pt: "Ferramentas profissionais",
    de: "Profiwerkzeuge",
    fr: "Outils professionnels",
    es: "Herramientas profesionales"
  },
  "Condos": {
    en: "Condos",
    pt: "Condomínios",
    de: "Wohnungen",
    fr: "Condominiums",
    es: "Condos"
  },
  "Houses": {
    en: "Houses",
    pt: "Casas",
    de: "Häuser",
    fr: "Maisons",
    es: "Casas"
  },
  "Long-term rentals": {
    en: "Long-term rentals",
    pt: "Aluguéis de longa duração",
    de: "Langzeitmieten",
    fr: "Locations à long terme",
    es: "Alquileres a largo plazo"
  },
  "Lots, gardens, fields": {
    en: "Lots, gardens, fields",
    pt: "Lotes, jardins, campos",
    de: "Grundstücke, Gärten, Felder",
    fr: "Terrains, jardins, champs",
    es: "Lotes, jardines, campos"
  },
  "Offices, workshops": {
    en: "Offices, workshops",
    pt: "Escritórios, oficinas",
    de: "Büros, Werkstätten",
    fr: "Bureaux, ateliers",
    es: "Oficinas, talleres"
  },
  "Real estate": {
    en: "Real estate",
    pt: "Imobiliária",
    de: "Immobilien",
    fr: "Immobilier",
    es: "Bienes raíces"
  },
  "Indoor activities": {
    en: "Indoor activities",
    pt: "Atividades interiores",
    de: "Indoor-Aktivitäten",
    fr: "Activités d'intérieur",
    es: "Actividades bajo techo"
  },
  "Outdoor activities": {
    en: "Outdoor activities",
    pt: "Atividades ao ar livre",
    de: "Outdoor-Aktivitäten",
    fr: "Activités de plein air",
    es: "Actividades al aire libre"
  },
  "Recreational Equipment": {
    en: "Recreational Equipment",
    pt: "Equipamento Recreativo",
    de: "Freizeitausrüstung",
    fr: "Équipement de loisirs",
    es: "Equipo recreativo"
  },
  "Products": {
    en: "Products",
    pt: "Produtos",
    de: "Produkte",
    fr: "Produits",
    es: "Productos"
  },
  "Outdoor adventures": {
    en: "Outdoor adventures",
    pt: "Aventuras ao ar livre",
    de: "Outdoor-Abenteuer",
    fr: "Aventures en plein air",
    es: "Aventuras al aire libre"
  },
  "Personalized tourist guidance": {
    en: "Personalized tourist guidance",
    pt: "Orientação turística personalizada",
    de: "Personalisierte Touristenführung",
    fr: "Guidage touristique personnalisé",
    es: "Guía turística personalizada"
  },
  "Educational workshops": {
    en: "Educational workshops",
    pt: "Oficinas educativas",
    de: "Bildungsworkshops",
    fr: "Ateliers éducatifs",
    es: "Talleres educativos"
  },
  "Entertainment & Leisure Activities": {
    en: "Entertainment & Leisure Activities",
    pt: "Atividades de entretenimento e lazer",
    de: "Unterhaltungs- und Freizeitaktivitäten",
    fr: "Activités de divertissement et de loisirs",
    es: "Actividades de entretenimiento y ocio"
  },
  "Experiences": {
    en: "Experiences",
    pt: "Experiências",
    de: "Erfahrungen",
    fr: "Expériences",
    es: "Experiencias"
  },
  "Short-term lodging": {
    en: "Short-term lodging",
    pt: "Alojamento de curta duração",
    de: "Kurzfristige Unterkunft",
    fr: "Hébergement à court terme",
    es: "Alojamiento a corto plazo"
  },
  "Restaurants, Catering & Private Culinary": {
    en: "Restaurants, Catering & Private Culinary",
    pt: "Restaurantes, Catering & Culinária Privada",
    de: "Restaurants, Catering & Private Culinary",
    fr: "Restaurants, traiteurs et culinaire privé",
    es: "Restaurantes, catering y culinaria privada"
  },
  "Health & Fitness": {
    en: "Health & Fitness",
    pt: "Saúde",
    de: "Gesundheit & Fitness",
    fr: "Santé & remise en forme",
    es: "Salud y bienestar"
  },
  "Beauty & Personal care": {
    en: "Beauty & Personal care",
    pt: "Beleza e cuidados pessoais",
    de: "Schönheit & Körperpflege",
    fr: "Beauté et soins personnels",
    es: "Belleza y cuidado personal"
  },
  "Pet services": {
    en: "Pet services",
    pt: "Serviços para animais de estimação",
    de: "Haustierdienste",
    fr: "Services pour animaux",
    es: "Servicios para mascotas"
  },
  "Hospitality & Lifestyle": {
    en: "Hospitality & Lifestyle",
    pt: "Hospitalidade e estilo de vida",
    de: "Gastfreundschaft & Lebensstil",
    fr: "Hôtellerie et style de vie",
    es: "Hospitalidad y estilo de vida"
  },
  "Community support": {
    en: "Community support",
    pt: "Suporte da comunidade",
    de: "Gemeinschaftliche Unterstützung",
    fr: "Soutien communautaire",
    es: "Apoyo comunitario"
  },
  "Cleaning & Sanitation": {
    en: "Cleaning & Sanitation",
    pt: "Limpeza e Saneamento",
    de: "Reinigung & Hygiene",
    fr: "Nettoyage et assainissement",
    es: "Limpieza y saneamiento"
  },
  "Digital services & E-commerce": {
    en: "Digital services & E-commerce",
    pt: "Serviços digitais e comércio eletrônico",
    de: "Digitale Dienstleistungen & E-Commerce",
    fr: "Services numériques et commerce électronique",
    es: "Servicios digitales y comercio electrónico"
  },
  "Home improvement & Garden care": {
    en: "Home improvement & Garden care",
    pt: "Melhoria da casa e cuidados com o jardim",
    de: "Heimwerker- und Gartenpflege",
    fr: "Amélioration de la maison et soins du jardin",
    es: "Mejora del hogar y cuidado del jardín"
  },
  "Legal, financial, and consulting aid": {
    en: "Legal, financial, and consulting aid",
    pt: "Assistência jurídica, financeira e de consultoria",
    de: "Rechtliche, finanzielle und beratende Hilfe",
    fr: "Aide juridique, financière et de conseil",
    es: "Asistencia legal, financiera y de consultoría"
  },
  "Real estate & Property Management": {
    en: "Real estate & Property Management",
    pt: "Imobiliário e Gestão de Propriedades",
    de: "Immobilien & Hausverwaltung",
    fr: "Immobilier et gestion immobilière",
    es: "Bienes raíces y administración de propiedades"
  },
  "Repair & Technical services": {
    en: "Repair & Technical services",
    pt: "Serviços técnicos e de reparo",
    de: "Reparatur- & technische Dienstleistungen",
    fr: "Services de réparation et techniques",
    es: "Servicios de reparación y técnicos"
  },
  "Professional Services": {
    en: "Professional Services",
    pt: "Serviços profissionais",
    de: "Professionelle Dienstleistungen",
    fr: "Services professionnels",
    es: "Servicios profesionales"
  },
  "Personal transportation": {
    en: "Personal transportation",
    pt: "Transporte pessoal",
    de: "Persönlicher Transport",
    fr: "Transport personnel",
    es: "Transporte personal"
  },
  "Professional logistics": {
    en: "Professional logistics",
    pt: "Logística profissional",
    de: "Professionelle Logistik",
    fr: "Logistique professionnelle",
    es: "Logística profesional"
  },
  "Transportation & Logistics": {
    en: "Transportation & Logistics",
    pt: "Transporte & Logística",
    de: "Transport & Logistik",
    fr: "Transport et logistique",
    es: "Transporte y logística"
  },
  "Services": {
    en: "Services",
    pt: "Serviços",
    de: "Dienstleistungen",
    fr: "Services",
    es: "Servicios"
  },
  "Public celebrations": {
    en: "Public celebrations",
    pt: "Celebrações públicas",
    de: "Öffentliche Feierlichkeiten",
    fr: "Célébrations publiques",
    es: "Celebraciones públicas"
  },
  "Religious events": {
    en: "Religious events",
    pt: "Eventos religiosos",
    de: "Religiöse Veranstaltungen",
    fr: "Événements religieux",
    es: "Eventos religiosos"
  },
  "Community Events": {
    en: "Community Events",
    pt: "Eventos comunitários",
    de: "Gemeinschaftsveranstaltungen",
    fr: "Événements communautaires",
    es: "Eventos comunitarios"
  },
  "Cultural & Gastronomy Events": {
    en: "Cultural & Gastronomy Events",
    pt: "Eventos Culturais e Gastronômicos",
    de: "Kultur- & Gastronomieveranstaltungen",
    fr: "Événements culturels et gastronomiques",
    es: "Eventos culturales y gastronómicos"
  },
  "Parties": {
    en: "Parties",
    pt: "Festas",
    de: "Partys",
    fr: "Fêtes",
    es: "Fiestas"
  },
  "Events Hosted by Private Enterprises": {
    en: "Events Hosted by Private Enterprises",
    pt: "Eventos organizados por empresas privadas",
    de: "Veranstaltungen von privaten Unternehmen",
    fr: "Événements organisés par des entreprises privées",
    es: "Eventos organizados por empresas privadas"
  },
  "Events": {
    en: "Events",
    pt: "Eventos",
    de: "Veranstaltungen",
    fr: "Événements",
    es: "Eventos"
  },
  "Accommodations & Rentals": {
    en: "Accommodations & Rentals",
    pt: "Acomodações e Aluguéis",
    de: "Unterkünfte und Vermietungen",
    fr: "Hébergements et Locations",
    es: "Alojamientos y Alquileres"
  },
  "Restaurant Reservations": {
    en: "Restaurant Reservations",
    pt: "Reservas de Restaurante",
    de: "Restaurantreservierungen",
    fr: "Réservations de Restaurant",
    es: "Reservas de Restaurante"
  },
  "Specialty Tourism Services": {
    en: "Specialty Tourism Services",
    pt: "Serviços de Turismo Especializados",
    de: "Spezielle Tourismusdienste",
    fr: "Services de Tourisme Spécialisés",
    es: "Servicios de Turismo Especializados"
  },
  "Transportation & Vehicle Rentals": {
    en: "Transportation & Vehicle Rentals",
    pt: "Transporte e Aluguel de Veículos",
    de: "Transport- und Fahrzeugvermietungen",
    fr: "Transport et Location de Véhicules",
    es: "Transporte y Alquiler de Vehículos"
  },
  "Tourist Experiences & Guided Tours": {
    en: "Tourist Experiences & Guided Tours",
    pt: "Experiências Turísticas e Passeios Guiados",
    de: "Touristenerlebnisse und Geführte Touren",
    fr: "Expériences Touristiques et Visites Guidées",
    es: "Experiencias Turísticas y Visitas Guiadas"
  },
  "Wellness & Spa Services": {
    en: "Wellness & Spa Services",
    pt: "Serviços de Bem-Estar e Spa",
    de: "Wellness- und Spa-Dienste",
    fr: "Services de Bien-être et Spa",
    es: "Servicios de Bienestar y Spa"
  },
  "Other": {
    en: "Other",
    pt: "Outros",
    de: "Andere",
    fr: "Autre",
    es: "Otros"
  },
  "Arts, Crafts & Souvenirs": {
    en: "Arts, Crafts & Souvenirs",
    pt: "Artes, Artesanato e Souvenirs",
    de: "Kunst, Handwerk und Souvenirs",
    fr: "Arts, Artisanat et Souvenirs",
    es: "Artes, Artesanías y Souvenirs"
  },
  "Local Food & Beverages": {
    en: "Local Food & Beverages",
    pt: "Comidas e Bebidas Locais",
    de: "Lokale Speisen und Getränke",
    fr: "Nourriture et Boissons Locales",
    es: "Comida y Bebidas Locales"
  },
  "Real Estate": {
    en: "Real Estate",
    pt: "Imóveis",
    de: "Immobilien",
    fr: "Immobilier",
    es: "Bienes Raíces"
  },
  "Community Events": {
    en: "Community Events",
    pt: "Eventos Comunitários",
    de: "Gemeinschaftsveranstaltungen",
    fr: "Événements Communautaires",
    es: "Eventos Comunitarios"
  },
  "Events Hosted by Private Enterprises": {
    en: "Events Hosted by Private Enterprises",
    pt: "Eventos Organizados por Empresas Privadas",
    de: "Veranstaltungen von Privaten Unternehmen",
    fr: "Événements Organisés par des Entreprises Privées",
    es: "Eventos Organizados por Empresas Privadas"
  }
};

export const filterparamsTranslations = {
  "Location": {
    en: "Location (island)",
    pt: "Localização (ilha)",
    de: "Standort (Insel)",
    fr: "Emplacement (île)",
    es: "Ubicación (isla)"
  },
  "Service Category": {
    en: "Service Category",
    pt: "Categoria Serviço",
    de: "Dienstleistungskategorie",
    fr: "Catégorie de service",
    es: "Categoría de servicio"
  },
  "Product Category": {
    en: "Product Category",
    pt: "Categoria Produto",
    de: "Produktkategorie",
    fr: "Catégorie de produit",
    es: "Categoría de producto"
  }
}

export const listingTypesTranslations = {
  "For sale": {
    en: "For sale",
    pt: "À venda",
    de: "Zu verkaufen",
    fr: "À vendre",
    es: "En venta"
  },
  "Daily rental": {
    en: "Daily rental",
    pt: "Aluguel diário",
    de: "Tagesmiete",
    fr: "Location journalière",
    es: "Alquiler diario"
  },
  "Hourly rental": {
    en: "Hourly rental",
    pt: "Aluguel horário",
    de: "Stundenmiete",
    fr: "Location à l'heure",
    es: "Alquiler por hora"
  },
  "Sell services by the hour": {
    en: "Sell services by the hour",
    pt: "Fornecer serviços por hora",
    de: "Dienstleistungen stundenweise anbieten",
    fr: "Vendre des services à l'heure",
    es: "Vender servicios por hora"
  },
  "Sell services by day": {
    en: "Sell services by day",
    pt: "Fornecer serviços por dia",
    de: "Dienstleistungen tageweise anbieten",
    fr: "Vendre des services à la journée",
    es: "Vender servicios por día"
  },
  "Lodging (nightly)": {
    en: "Lodging (nightly)",
    pt: "Alojamento (noturna)",
    de: "Übernachtung",
    fr: "Hébergement (nuitée)",
    es: "Alojamiento (nocturno)"
  },
  "Event (with booking)": {
    en: "Event (with booking)",
    pt: "Evento (com reservas)",
    de: "Veranstaltung (mit Buchungen)",
    fr: "Événement (sur réservations)",
    es: "Evento (con reserva)"
  },
  "Event (no bookings)": {
    en: "Event (no bookings)",
    pt: "Evento (sem reservas)",
    de: "Veranstaltung (keine Buchungen)",
    fr: "Événement (pas de réservations)",
    es: "Evento (sin reservas)"
  }
};

export const userFieldsTranslations = {
  "I am a ...": {
    en: "I am a ...",
    pt: "Eu sou ...",
    de: "Ich bin ein ...",
    fr: "Je suis un ...",
    es: "Yo soy un ..."
  },
  "visitor to the Azores.": {
    en: "visitor to the Azores.",
    pt: "visitante nos Açores.",
    de: "Besucher der Azoren.",
    fr: "visiteur des Açores.",
    es: "visitante de las Azores."
  },
  "local to the Azores.": {
    en: "local to the Azores.",
    pt: "local dos Açores.",
    de: "Einheimischer auf den Azoren.",
    fr: "local des Açores.",
    es: "local de las Azores."
  },
  "Language preference": {
    en: "Language preference",
    pt: "Idioma preferido",
    de: "Spracheinstellung",
    fr: "Préférence linguistique",
    es: "Preferencia de idioma"
  },
  "English": {
    en: "English",
    pt: "Inglês",
    de: "Englisch",
    fr: "Anglais",
    es: "Inglés"
  },
  "Portuguese": {
    en: "Portuguese",
    pt: "Português",
    de: "Portugiesisch",
    fr: "Portugais",
    es: "Portugués"
  },
  "German": {
    "en": "German",
    "pt": "Alemão",
    "de": "Deutsch",
    "fr": "Allemand",
    "es": "Alemán"
  },
  "French": {
    "en": "French",
    "pt": "Francês",
    "de": "Französisch",
    "fr": "Français",
    "es": "Francés"
  },
  "Spanish": {
    "en": "Spanish",
    "pt": "Espanhol",
    "de": "Spanisch",
    "fr": "Espagnol",
    "es": "Español"
  },
  "Location": {
    "en": "Location",
    "pt": "Localização",
    "de": "Standort",
    "fr": "Emplacement",
    "es": "Ubicación"
  }
}